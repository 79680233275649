/* Styles defined here will be globally available */

@import 'styles/variables.scss';

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-padding: 180px 0 0 0;

  @media (min-width: $screens-md) {
    scroll-padding: 120px 0 0 0;
  }
}

::-moz-selection {
  background: rgba(255, 202, 88, 0.5);
}

::selection {
  background: rgba(255, 201, 10, 0.5);
}

.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-wrapper header,
.page-wrapper footer {
  flex-grow: 0;
}

.page-wrapper main {
  flex-grow: 1;
}

.layout-default {
  max-width: $screens-xl;
  margin-inline: auto;
  padding-inline: var(--spacing-5);
}

@media (min-width: $screens-md) {
  .layout-default {
    padding-inline: var(--spacing-4);
  }
}

.main-centered {
  max-width: 52rem;
  margin-inline: auto;
}

.stretched-link {
  &::before {
    position: absolute;
    inset: 0;
    content: ' ';
  }
}

input::placeholder {
  @media (max-width: $screens-sm) {
    font-size: var(--text-lg-size);
  }
}

/*! purgecss ignore */
.full-lazyload .lazyload-wrapper {
  height: 100%;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.text-with-links a {
  color: var(--color-navy);
  text-decoration-line: underline;
}

.force-visible {
  visibility: visible !important;
}

[type='button']:focus-visible,
[type='reset']:focus-visible,
[type='submit']:focus-visible,
button:focus,
button:focus-visible,
button:focus-within {
  outline: none !important;
}
