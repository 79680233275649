@import './variables.css';
@import './variables.scss';
@import 'tailwindcss/base';
@import './fonts.scss';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './typography.scss';
@import './globals.scss';
/*! purgecss start ignore */
@import './algolia.scss';
@import 'react-loading-skeleton/dist/skeleton';
@import '@uppy/core/dist/style';
@import '@uppy/dashboard/dist/style';
@import '@uppy/webcam/dist/style';
@import 'components/carousel-filter.scss';
@import 'components/timeline.scss';

.no-inline-block-image {
  source + div {
    display: block !important;
  }
}

.carousel-rounded-image {
  source + div > img {
    height: auto !important;
    min-height: auto !important;
  }
}

.banner-content a:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

#optanon #optanon-popup-wrapper {
  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .optanon-button-wrapper {
    margin-top: var(--spacing-3);

    @media (max-width: $screens-md) {
      display: inline-flex;
      justify-content: center;
      width: 100%;
      text-align: center;
      float: none !important;
    }

    .optanon-white-button-middle {
      line-height: 1;
      float: none;
      display: inline-block;
    }
  }
}

.react-multi-carousel-dot-list {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin-inline: auto;
  justify-content: center;
  text-align: center;
  list-style-type: none;
}

.uppy-Root {
  z-index: 20 !important;
}

.outerContainer {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.fluid-lazyload .lazyload-wrapper {
  width: 100% !important;
  height: 200px;

  @media (min-width: $screens-xl) {
    height: 250px;
  }
}

body.conversion-bar-visible
  .eapp-whatsapp-chat-root-layout-component
  > div
  > div {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transform: translateY(-71px);
}

.very-high-z-index {
  z-index: 9999999999 !important;
}

.conversion-bar-open {
  transform: translate(0%) !important;
}

form input[type='text'],
form input[type='email'] {
  border: 2px solid var(--color-bluishGrey);
}

form input[type='text']:focus,
form input[type='email']:focus {
  border-color: var(--color-secondary);
}

.carousel-item img {
  max-height: 95vh;
}

.quno-search {
  & .ais-SearchBox-input {
    background: var(--color-grandisLight);
    border: none;
    color: var(--color-siam);
    font-size: var(--text-xl-size);
    letter-spacing: normal;
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: 0;

    &::placeholder {
      color: var(--color-siam) !important;
      font-size: var(--text-xl-size);
      letter-spacing: normal;
    }
  }

  & .ais-SearchBox-reset {
    top: var(--spacing-3);
    display: none;
  }
}

[class^='Image_image__'] {
  animation: none !important;
}

.word-rotator span {
  opacity: 0;
  top: 0.2em;
  position: absolute;
  width: 100%;
  left: 0;

  &.is-visible {
    top: 0;
    opacity: 1;
    animation: slide-in 0.6s;
  }

  &.is-hidden {
    animation: slide-out 0.6s;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  60% {
    opacity: 1;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  60% {
    opacity: 0;
    transform: translateY(120%);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.white-link a {
  color: var(--color-white);
  text-decoration: underline;
}

/*! purgecss end ignore */
