@import 'styles/functions.scss';

.ais-SearchBox {
  input {
    appearance: none;
    border: 1px solid var(--color-silverLight);
    border-radius: var(--spacing-1);
    padding: var(--spacing-3) var(--spacing-5);
    width: 100%;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      appearance: none;
    }
  }
  &-reset {
    right: var(--spacing-4);
    position: absolute;
    top: var(--spacing-5);
  }
}

.ais-RefinementList {
  &-count {
    display: none;
  }

  &-checkbox {
    vertical-align: middle;
  }

  &-label {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &Text {
      flex-grow: 1;
    }
  }
}

.ais-ToggleRefinement {
  &-labelText {
    color: var(--textColor-primary);
    font-size: var(--text-xs-size);
  }

  &-checkbox {
    vertical-align: middle;
  }
}

.ais-ClearRefinements-button {
  color: var(--color-siam);
  margin-top: var(--spacing-4);
}

.ais-Pagination {
  display: flex;
  justify-content: center;

  &-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-inline: var(--spacing-2);

    a,
    span {
      display: flex;
      align-items: center;
      background-color: var(--color-white);
      border: 1px solid var(--color-silverLight);
      border-radius: 9999px;
      color: var(--textColor-secondary);
      font-size: var(--text-base-size);
      height: var(--spacing-8);
      justify-content: center;
      text-align: center;
      text-decoration: none !important;
      transition: background-color 0.3s ease-in-out;
      width: var(--spacing-8);

      &:hover {
        background-color: var(--color-navy);
        border: 1px solid var(--color-navy);
        color: var(--color-white);
      }
    }

    &--selected a {
      background-color: var(--color-navy);
      border: 1px solid var(--color-navy);
      color: var(--color-white);
    }

    &--nextPage span,
    &--nextPage a,
    &--previousPage span,
    &--previousPage a {
      border-color: var(--color-white) !important;
    }
  }

  &--noRefinement {
    display: none;
  }
}

.ais-ClearRefinements-button {
  text-decoration: underline;
}

.mobile-filters-bottom .ais-ClearRefinements-button {
  margin-top: toRem(5px);
}

.ais-Panel.ais-Panel--noRefinement {
  display: none;
}

.ais-MenuSelect {
  &-select {
    appearance: none;
    background: {
      color: var(--color-white);
      image: url('/v2/images/arrow-down.svg');
      position: 95%;
      repeat: no-repeat;
    }
    border: 1px solid var(--color-silverLight);
    border-radius: var(--spacing-1);
    color: var(--color-charcoal);
    overflow: hidden;
    padding: var(--spacing-3);
    padding-right: var(--spacing-8);
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; // width: 80%;
  }
}

.doctors-list {
  .ais-SearchBox-input,
  .ais-MenuSelect-select {
    background: var(--color-bluishGrey);
    border-color: var(--color-bluishGrey);
  }

  .dl-language {
    &__control {
      background-color: var(--color-bluishGrey);
      border-color: var(--color-bluishGrey);
    }

    &__indicator-separator {
      visibility: hidden;
    }

    &__menu {
      border-radius: 0 0 0 var(--spacing-2);
      padding: var(--spacing-2);
    }

    &__option {
      background: var(--color-white);
      border-radius: var(--spacing-1);
      color: var(--color-charcoal);

      &:hover {
        background: var(--color-secondary);
        cursor: pointer;
      }
    }

    &__dropdown-indicator {
      svg {
        fill: #909090;
      }
    }

    &__single-value {
      color: var(--color-charcoal);
    }

    &__value-container {
      padding-top: var(--spacing-2-5);
      padding-bottom: var(--spacing-2-5);
    }
  }

  .ais-RefinementList-item {
    margin-bottom: var(--spacing-1);
  }
}
